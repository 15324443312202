<template>
  <div>
    <vue-confirm-dialog></vue-confirm-dialog>

      <h3 class="mb-3">
         Dados Responsavel
      </h3>
      <div class="col-md-12">
        <div class="form">
          <div class="row form-group">
            <div class="col-md-6">
              <label for="nome" >Nome:*</label>
              <input
                required=""
                type="text"
                class="form-control"
                id="nome"
                v-model="form.nome"
                placeholder="Digite o nome..."
              />
            </div>
            <div class="col-md-6">
              <label for="sobrenome" >Sobrenome:*</label>
              <input
                required=""
                type="text"
                class="form-control"
                id="sobrenome"
                v-model="form.sobrenome"
                placeholder="Digite o Sobrenome..."
              />
            </div>
            </div>
          <div class="row form-group">

            <div class="col-md-4">
              <label>Data de Nascimento:*</label>
              <input
                required=""
                type="date"
                class="form-control"
                id="form_control_1"
                v-model="form.data_nascimento"
                placeholder="Data de nascimento..."
              />
            </div>
            <div class="col-md-4">
              <label for="cpf" >CPF*</label>
              <input
                required=""
                type="text"
                class="form-control"
                id="cpf"
                v-mask="variableWithMask"
                v-model="cpf_cnpj1"
                placeholder="Digite o cpf ..."
              />
            </div>
            <div class="col-md-4 pull-left">
              <label for="telefone1">Telefone 1:*</label>
              <input
                required=""
                type="text"
                class="form-control"
                id="telefone1"
                v-model="tel1"
                v-mask="variableWithMaskTel"
                placeholder="Digite o telefone/celular..."
              />
            </div>
          </div>

          <div class="row form-group">
     

      
            <div class="col-md-6 pull-left">
              <label>Email:*</label>
              <input type="email" required v-model="form.email" class="form-control" />
            </div>
            <div class="col-md-6 pull-left">
              <label>Senha:*</label>
              <input placeholder="sua senha" required v-model="form.password" class="form-control" />
            </div>
            
          </div>

          <div class="row form-group">
            <h4 class="col-md-12">Endereço:</h4>
            <div class="col-md-3">
              <label>CEP:*</label>
              <input
                required=""
                type="text"
                class="form-control"
                v-model="form.cep"
                placeholder="cep ...."
                @input="set_cep()"
              />
            </div>
            <div class="col-md-6">
              <label>Rua:*</label>
              <input
                required=""
                type="text"
                class="form-control"
                v-model="form.rua"
                placeholder="Rua ...."
              />
            </div>
            <div class="col-md-3">
              <label for="endereco_numero" >Numero:*</label>
              <input
                type="text"
                class="form-control"
                id="endereco_numero"
                v-model="form.numero"
                placeholder="numero ...."
              />
            </div>
            </div>
          <div class="row form-group">

            <div class="col-md-4">
              <label for="endereco_bauirro" >Cidade:*</label>
              <input
                required
                type="text"
                class="form-control"
                id="endereco_bauirro"
                v-model="form.cidade"
                placeholder="Digite a cidade..."
              />
            </div>
            <div class="col-md-4">
              <label for="endereco_bauirro" >Estado:*</label>
              <input
                required
                type="text"
                class="form-control"
                id="endereco_bauirro"
                v-model="form.uf"
                placeholder="Digite o estado..."
              />
            </div>
            <div class="col-md-4">
              <label for="endereco_complemento" 
                >Complemento:</label
              >
              <input
                type="text"
                class="form-control"
                id="endereco_complemento"
                v-model="form.complemento"
                placeholder="Digite o complento..."
              />
            </div>


            <div class="col-md-4 pt-2">
              <label for="endereco_bauirro" >Bairro:*</label>
              <input
                required
                type="text"
                class="form-control"
                id="endereco_bauirro"
                v-model="form.bairro"
                placeholder="Digite o bairro..."
              />
            </div>
          </div>

          <div class="row form-gro up">
            <h4 class="col-md-12">Dados :</h4>

            <div class="col-md-6">
              <label>Perfil:*</label>
              <treeselect
              :disabled="true"
                :multiple="false"
                :options="lista_perfil"
                placeholder="Selecione o Setor..."
                v-model="form.perfil_id"
              />
            </div>
      

          </div>
    
        </div>
      </div>

      <div class="row justify-content-end">
        <div class="form-group row">
          <div class="col-md-12 text-center">
            <button
              @click.prevent="confirm('cria')"
              class="btn btn-light-primary font-weight-bolder font-size-h6 px-4 py-2 my-3"
              :disabled="verif"
            >
              Salvar
              <b-spinner
                v-show="verif"
                small
                variant="dark"
                type="grow"
                label="Spinning"
              ></b-spinner>
            </button>
          </div>
        </div>
      </div>

      <!-- </div> -->
  </div>
</template>

<script>
import { searchAddressMixin } from "@/core/mixins/searchAddressMixin";
import { fireAlert } from "@/core/mixins/alertMessageMixin";
import UsuarioService from "@/core/services/usuario.service";
import { SET_BREADCRUMB } from "@/core/store/breadcrumbs.module";

export default {
  mixins: [searchAddressMixin, fireAlert],
  data() {
    return {
      form: {
        nome: null,
        sobrenome: null,
        cpf: null,
        data_nascimento: null,
        cep: null,
        rua: null,
        bairro: null,
        cidade: null,
        email: null,
        password: null,

        status: 1,
        telefone1: null,
        ddd: null,

        perfil_id: 2,
        contrato_funcionario_id: 2,
        setor_id: null,
        login: null,

      },
      paramestado: "",
      value: "",
      loading: false,
      verif: false,
      variableWithMask: null,
      cpf_cnpj1: null,
      tel1:null,
      variableWithMaskTel :null,

    };
  },

  computed: {

    mensagem_alert() {
      return this.$store.state.offline.mensagem_alert;
    },

    lista_perfil() {
      return this.$store.state.offline.lista_perfil.map((cat) => ({
        id: cat.id,
        label: cat.nome,
      }));
    },


    cep() {
      return this.$store.state.offline.cep;
    },
   
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Usuarios" }]);
  },
  created() {
    this.listar_perfil();
    // this.preenxerCampor();
  },
  watch: {
    cpf_cnpj1: function () {
      this.variableWithMask =
        this.cpf_cnpj1.replace(/[^0-9]/g, "").length <= 11
          ? "###.###.###-##"
          : "##.###.###/####-##";
      this.form.cpf = this.cpf_cnpj1.replace(/[^0-9]/g, "");
    },
    tel1: function () {
      this.variableWithMaskTel ="(##)9####-####";

        this.form.telefone1 = this.tel1.replace(/[^0-9]/g, "");
        this.form.ddd = this.form.telefone1.substring(0, 2);
    },
  },
  methods: {
    async listar_perfil() {
  
   
      await this.$store.dispatch("offline/listar_perfil").finally(() => {
        // this.$store.dispatch("atualizar", "");
      });
    },
    async confirm(tipo) {
      await this.$confirm({
        title: "Tem certeza?",
        message: `Esta ação  ` + tipo + ` seu usuario?`,
        button: {
          no: "Não",
          yes: "Sim",
        },
        callback: (confirm) => {
          if (confirm) {
            if (tipo == "cria") this.create();
          }
        },
      });
    },
    async create() {
      this.verif = true;
      this.form.login = this.form.email
      await this.$store.dispatch("offline/create_usuario", this.form);
      this.verif = false;
      this.fireAlert({
        ...this.mensagem_alert
      });
      window.location.reload();

    },
  

    async set_cep() {
      this.form.cep = this.form.cep.replace(/[^0-9]/g, "");
      if (this.form.cep.length == 8) {
        await this.$store.dispatch("offline/set_cep", this.form.cep);
      }

      this.form.rua = this.cep.logradouro;
      this.form.cidade = this.cep.localidade;
      this.form.uf = this.cep.uf;
      this.form.bairro = this.cep.bairro;
      this.form.complemento = this.cep.complemento;
    },

  },
};
</script>

<style scoped>
.lista:hover {
  background-color: rgba(167, 183, 255, 0.705);
}
</style>